<template>
	<div class="footer">
		<div style="background-color:#383d61" class="footer--bg"></div>
		<div class="footer--inner">
			<div class="am-g">
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">服务条款</strong>
						<ul class="footer_navigation">
							<li class="footer_navigation--item"><a href="/#/useragreement" target="_blank" class="footer_navigation--link">使用协议</a></li>
							<li class="footer_navigation--item"><a href="/#/privacy" target="_blank" class="footer_navigation--link">用户隐私协议</a></li>
							<li class="footer_navigation--item"><a href="/#/solemnlyDeclare" target="_blank" class="footer_navigation--link">郑重申明</a></li>
						</ul>
					</div>
				</div>
				<div class="am-u-md-6 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title"></strong>
						<div class="footer_about">
							<div class="code-item" v-for="item in codeList" :key="item.id">
                <div v-if="item.download">
                  <a class="download-a" v-if="item.download" target="_blank" :href="item.download"><img :src="item.url" :alt="item.name"></a>
                  <a class="download-a" v-if="item.download" target="_blank" :href="item.download">{{item.name}}</a>
                </div>
                <div v-if="item.download === undefined">
                  <img :src="item.url" :alt="item.name">
                  <span>{{item.name}}</span>
                </div>
							</div>
						</div>
						<div class="copyright">Copyright © 2024 道道明 All Rights Reserved <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2024042233号-2</a></div>
					</div>
				</div>
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">联系详情</strong>
						<ul class="footer_contact_info">
<!--							<li class="footer_contact_info&#45;&#45;item"><i class="am-icon-phone"></i><span>服务专线：18611121058</span></li>-->
							<li class="footer_contact_info--item"><i class="am-icon-map-marker"></i><span>公司地址：海南省海口市龙华区世贸东路2号世贸雅苑F座804</span></li>
<!--							<li class="footer_contact_info&#45;&#45;item"><i class="am-icon-link"></i><span><a target="_blank" href="##" class="hx-btn">在线咨询</a></span></li>-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer",
	data(){
		return{
			codeList:[
				{
					id:1,
					name:'道道明课堂App',
					download:'https://dow.xiafenfa.cn/utuupn',
					url:require('../../../assets/images/download-app-qrcode-new.png')
				},
				// {
				// 	id:2,
				// 	name:'道道明课堂',
				// 	url:require('../../../assets/images/footer2.png')
				// },
				{
					id:3,
					name:'客服',
					url:require('../../../assets/images/footer3.png')
				},
				{
					id:4,
					name:'道道明题库',
					url:require('../../../assets/images/add/footer1.jpg')
				},
				{
					id:5,
					name:'道道明小程序',
					url:require('../../../assets/images/add/footer2.jpg')
				},
				{
					id:6,
					name:'道道明公众号',
					url:require('../../../assets/images/add/footer3.jpg')
				},{
					id:7,
					name:'H5',
					url:require('../../../assets/images/add/h5.png')
				}
			]
		}
	},
}
</script>

<style lang="less" scoped>
	.footer_about{
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		.code-item{
			display: inline-block;
			text-align: center;
			color: #fff;
			margin-bottom: 12px;
			img{
				display: block;
				width: 140px;
				height: 140px;
				margin-bottom: 6px;
			}
		}
	}
	.footer{
		.hx-btn{
			font-weight: 500;
			border: none;
			outline: none;
			cursor: pointer;
			display: inline-block;
			font-size: 14px;
			border-radius: 30px;
			line-height: 14px;
			padding: 8px 21px;
			background: #ff6c3b;
			color: #fff;
		}
    .download-a{
      color: #eee;
      &:hover{
        opacity: .75;
      }
    }
		.copyright{
			margin-top: 15px;
			margin-bottom: 10px;
			text-align: center;
			color: #eee;
			font-size: 13px;

			a{
				color: #eee;
				&:hover{
					opacity: .75;
				}
			}
		}
	}
</style>
